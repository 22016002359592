#########################################################################
### QuestionnaireItemResponseValidator
#########################################################################
class QuestionnaireItemResponseValidator

    constructor: (questionnaireItem, questionnaireTriggers, respondentResponses, invalidIds) ->
        @questionnaireItem      = questionnaireItem
        @questionnaireTriggers  = questionnaireTriggers
        @respondentResponses    = respondentResponses
        @invalidIds             = invalidIds

    isQuestionnaireItemResponseValid :->
        return true


#########################################################################
### IndicatorItemResponseValidator
#########################################################################
class IndicatorItemResponseValidator extends QuestionnaireItemResponseValidator

    isQuestionnaireItemResponseValid :->
        questionnaireItemValid = true
        for subindicatorId,subindicatorRequired of @questionnaireItem.required
            if subindicatorRequired
                if @respondentResponses.hasOwnProperty(@questionnaireItem.id)
                    if @respondentResponses[@questionnaireItem.id].hasOwnProperty(subindicatorId)
                        if @respondentResponses[@questionnaireItem.id][subindicatorId] == null
                            questionnaireItemValid = false
                    else
                        questionnaireItemValid = false
                else
                    questionnaireItemValid = false
        return questionnaireItemValid


#########################################################################
### SuccessfactorItemResponseValidator
#########################################################################
class SuccessfactorItemResponseValidator extends QuestionnaireItemResponseValidator

    constructor: (questionnaireItem, questionnaireTriggers, respondentResponses, invalidIds) ->
        super(questionnaireItem, questionnaireTriggers, respondentResponses, invalidIds)
        @indicators = []
        for item in @questionnaireItem.items
            if @questionnaireTriggers.isQuestionnaireItemVisible(item, respondentResponses)
                @indicators.push new IndicatorItemResponseValidator(item,questionnaireTriggers,respondentResponses,invalidIds)

    isQuestionnaireItemResponseValid :->
        questionnaireItemValid = true
        for indicatorValidator in @indicators
            if not indicatorValidator.isQuestionnaireItemResponseValid()
                questionnaireItemValid = false
                @invalidIds.push indicatorValidator.questionnaireItem.id
        return questionnaireItemValid


#########################################################################
### MatrixQuestionItemResponseValidator
#########################################################################
class MatrixQuestionItemResponseValidator extends QuestionnaireItemResponseValidator

    constructor: (questionnaireItem, questionnaireTriggers, respondentResponses, parent, invalidIds) ->
        super(questionnaireItem, questionnaireTriggers, respondentResponses, invalidIds)
        @parent = parent

    isQuestionnaireItemResponseValid :->
        questionnaireItemValid = true
        if @questionnaireItem.required
            for subindicator in @parent.subindicators
                subindicatorId = subindicator.id
                if @respondentResponses.hasOwnProperty(@questionnaireItem.id)
                    if @respondentResponses[@questionnaireItem.id].hasOwnProperty(subindicatorId)
                        if @respondentResponses[@questionnaireItem.id][subindicatorId] == null
                            questionnaireItemValid = false
                    else
                        questionnaireItemValid = false
                else
                    questionnaireItemValid = false
        return questionnaireItemValid


#########################################################################
### MatrixItemResponseValidator
#########################################################################
class MatrixItemResponseValidator extends QuestionnaireItemResponseValidator

    constructor: (questionnaireItem, questionnaireTriggers, respondentResponses, invalidIds) ->
        super(questionnaireItem, questionnaireTriggers, respondentResponses, invalidIds)
        @questions = []
        for item in @questionnaireItem.items
            if @questionnaireTriggers.isQuestionnaireItemVisible(item, respondentResponses)
                @questions.push new MatrixQuestionItemResponseValidator(item, questionnaireTriggers, respondentResponses,@questionnaireItem,invalidIds)

    isQuestionnaireItemResponseValid :->
        questionnaireItemValid = true
        for questionValidator in @questions
            if not questionValidator.isQuestionnaireItemResponseValid()
                questionnaireItemValid = false
                @invalidIds.push questionValidator.questionnaireItem.id
        return questionnaireItemValid


#########################################################################
### QuestionItemResponseValidator
#########################################################################
class QuestionItemResponseValidator extends QuestionnaireItemResponseValidator

    isQuestionnaireItemResponseValid :->
        questionnaireItemValid = true
        if @questionnaireItem.required
            if @respondentResponses.hasOwnProperty(@questionnaireItem.id)
                if @respondentResponses[@questionnaireItem.id]
                    if @questionnaireItem.form == 'SINGLECHOICE'
                        if @respondentResponses[@questionnaireItem.id] == null
                            questionnaireItemValid = false
                    if @questionnaireItem.form == 'MULTIPLECHOICE'
                        answersids = []
                        for key,val of @respondentResponses[@questionnaireItem.id]
                            if val
                                answersids.push(key)
                        if answersids.length == 0
                            questionnaireItemValid = false
                        if @questionnaireItem.hasOwnProperty('minimum')
                            if answersids.length < @questionnaireItem.minimum
                                questionnaireItemValid = false
                        if @questionnaireItem.hasOwnProperty('maximum')
                            if answersids.length > @questionnaireItem.maximum
                                questionnaireItemValid = false
                else
                    questionnaireItemValid = false
            else
                questionnaireItemValid = false
        if not questionnaireItemValid
            @invalidIds.push @questionnaireItem.id
        return questionnaireItemValid
